/* You can add global styles to this file, and also import other style files */
@import "~ag-grid/dist/styles/ag-grid.css";
@import "~ag-grid/dist/styles/ag-theme-material.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@fortawesome/fontawesome-pro/css/all.css";
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


html {
  height: 100%;
  border: 1px solid #cccccc;
}

body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

html, body {
  overflow: hidden;
}

input, button, select {
  font-family: "Open Sans", sans-serif;
}

button:focus {
  outline: none;
}
:focus {
  outline: none;
}

* {
  user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flexContainer {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexAuto {
  flex: auto;
}
.flexBasis0 {
  flex-basis: 0;
}
.flexInitial {
  flex: initial;
}
.space-between {
  justify-content: space-between;
}
.closeBtn {
  width: 15px;
  font-size: 12px;
  color: #ffffff;
}

.grey-color-button {
  background-color: #78849b !important;
  box-shadow: none !important;
  color: #fff !important;
}
.grey-color-button:disabled,
.grey-color-button[disabled] {
  opacity: 0.5;
}


.fullWidth {
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.draggable {
  -webkit-app-region: drag;
}
.hidden {
  display: none;
}
.display-inline {
  display: inline !important;
}
.visible {
  visibility: visible !important;
}

.windowErrorDiv {
  position: fixed;
  top: 50px;
  right: 1px;
  width: 100%;
  color: #ffffff;
  z-index: 1000;
  background-color: #cb0000;
  opacity: .77;
  text-align: center;
  font-size: 12px;
  padding: 0 5px;
}

.windowErrorDiv .mat-icon {
  font-size: 14px;
  line-height: 21px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.detailRow {
  background-color: #f6f6f6;
}

.connectionIndicator {
  float: left;
  margin-left: 5px;
}
.connectionIndicatorCircle {
  height: 8px;
  width: 8px;
  background-color: #ED145B;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.connected .connectionIndicatorCircle {
  background-color: #64AA45;
}
.partial .connectionIndicatorCircle {
  background-color: #d8d540;
}


/* ag-grid overrides */

.ag-header {
  background-color: #5674b1;
  font-size: 10px !important;
}

.ag-header-cell {
  font-family: "Open Sans", sans-serif !important;
  font-size: 10px !important;
  font-weight: normal !important;
  color: #ffffff;
  line-height: 30px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
}

.ag-header-cell.bold {
  font-weight: bold !important;
}

.ag-header-icon {
  color: #ffffff;
}

.ag-header-group-cell {
  line-height: 30px !important;
}

.ag-header-cell-resize::after {
  margin-top: 3px !important;
  border: none !important;
}

.ag-theme-material .ag-header-row {
  top: 1px !important;
}

.ag-cell {
  line-height: 30px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 10px !important;
  color: #22313b !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
  overflow: hidden;
}
.administration .ag-cell {
  font-size: 12px!important;
}
.strike-cell.ag-cell {
  overflow: inherit;
}
.ag-cell.changed-cell {
  background-color: #93B2E5;
}

.ag-icon-menu {
  height: 30px !important;
}

.ag-body-viewport {
  background-color: #e9e9e9;
}

.ag-body-container {
  background-color: #ffffff;
}

.ag-row {
  border-bottom: 1px solid #ececec !important;
}

.ag-theme-material .ag-cell-data-changed {
  background-color: #7794ce !important;
}

/*.ag-theme-material .ag-cell-data-changed-animation {*/
/*background-color: transparent;*/
/*transition: background-color 4s !important; }*/


.ag-theme-material .ag-row-hover {
  z-index: 2;
}
.ag-row-level-1 {
  background-color: #F6F6F6;
}
.hide-on-hover {
  display: none;
}

.ag-row-hover .hide-on-hover {
  display: block;
}

.tooltip-container {
  position: relative;
}
.ag-cell .tooltip {
  display: none;
}
.ag-cell:hover {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-cell:hover .tooltip {
  display: block;
  content: attr(data-tooltip);
  background-color: #707070;
  position: absolute;
  left: 5px;
  padding: 5px;
  color: #fff;
  line-height: 10px;
  border-radius: 2px;
  top: 30px;
}
.tooltip .tooltip-column {
  display: inline-block;
}
.tooltip .tooltip-row {
  padding: 1px 9px 0 9px;
  text-align: center;
}
.ag-details-row {
  padding: 0 !important;
  background-color: #f6f6f6;
}
.ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 20px !important;
}
.blotterGrid .ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
  padding-left: 0 !important;
}
.ag-theme-material .ag-group-expanded span {
  margin-right: 3px !important;
  background-position-y: 5px !important;
}
.ag-theme-material .ag-group-contracted span {
  margin-right: 3px !important;
  background-position-y: 5px !important;
}
.ag-details-grid .ag-body-viewport {overflow: hidden;}

.no-content-background .ag-body-viewport {
  background-color: transparent;
}

.no-horizontal-scroll .ag-body-viewport {
  overflow-x: hidden;
}

/* Angular Material overrides */

.no-underline .mat-form-field-underline {
  display: none;
}

.mat-form-field.mat-focused .mat-form-field-ripple,
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary,
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar,
.mat-calendar-body-selected {
  background-color: #3961a6;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #3961a6;
}

.mat-fab.mat-primary:hover, .mat-flat-button.mat-primary:hover, .mat-mini-fab.mat-primary:hover, .mat-raised-button.mat-primary:hover {
  background-color: #9CBCF2;
}

.mat-placeholder-required {
  color: #4e6fac;
}

.mat-menu-panel {
  -webkit-app-region: no-drag;
}

.mat-menu-item {
  font-size: 12px;
}

.mat-menu-item img {
  padding-right: 10px;
}

.mat-stepper-horizontal {
  font-family: "Open Sans", sans-serif !important;
}

.mat-horizontal-stepper-header-container {
  border-bottom: 1px solid #dcdcdc;
}

.mat-horizontal-stepper-header {
  text-transform: uppercase;
  height: 36px !important;
  padding: 0 12px !important;
}

.mat-stepper-horizontal-line {
  border-top-width: 0px !important;
  flex: initial !important;
}

.mat-step-icon {
  background-color: #ffffff !important;
  color: #3961a6 !important;
  border: 1px solid #3961a6;
  font-size: 10px;
  height: 18px !important;
  width: 18px !important;
}

.mat-step-icon-not-touched {
  background-color: #ffffff !important;
  color: #9fa0a2 !important;
  border: 1px solid #9fa0a2;
  font-size: 10px;
  height: 18px !important;
  width: 18px !important;
}

.mat-horizontal-content-container {
  padding: 0 10px 10px 10px !important;
}

.mat-step-text-label {
  font-size: 12px;
}

.mat-step-label-active {
  color: #6b6767 !important;
}
.mat-list-item {
  font-family: "Open Sans", sans-serif;
}
.mat-progress-spinner circle {
  stroke: #ffffff !important;
}
.countdownSpinner circle {
  stroke: #78dbf9 !important;
}
.countdownSpinnerWarning circle {
  stroke: #fcad02 !important;
}
.countdownSpinnerFinal circle {
  stroke: #fa4343 !important;
}
.countdownSpinnerWhite circle {
  stroke: #ffffff !important;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.grid-cell-icon .material-icons {
  font-size: 16px;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3F61A6 !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #3F61A6 !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #3F61A6 !important;
}
.mat-autocomplete-panel .mat-option {
  line-height: 36px!important;
  font-size: 12px!important;
  height: 36px!important;
}
.checkbox-container mat-checkbox {
  max-width: 100%;
}
.checkbox-container .mat-checkbox-layout {
  max-width: 100%;
}
.checkbox-container .mat-checkbox-label {
  overflow: hidden;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element{background-color:rgba(63,97,166,.26) !important;}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element{background-color:rgba(63,97,166,.26) !important;}
.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element{background-color:rgba(63,97,166,.26) !important;}

.edf-quick-datepicker {
  height: 100% !important;
}

.ag-theme-material .ag-row-selected {
  background-color: #cbcbcb !important;
}
.markit-submit-bar  {
  padding: 3px;
  background-color: #cbcbcb;
  text-align: right;
}

.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-color: white;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMzZjYxYTYiLz48L3N2Zz4=);
}
